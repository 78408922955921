'use strict'

function test() {
  if(Math.random() >= 0.5){return Promise.resolve('resolve')}else{return Promise.reject('reject')}
}

export default {
  increase: (state) => {
    state.count++
    console.log(state.count)
    test().then((result) => {
      console.log(result)
    }).catch((err) => {
      console.log(err)
    });
  }
}