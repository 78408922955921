<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-button :loading="creating" @click="create" :disabled="editing" type="primary">新建讲师</el-button>
      </el-row>
      <el-table :data="list" height="80vh">
        <el-table-column type="index" :index="getIndex" label="序号" />
        <el-table-column prop="name" label="讲师姓名" />
        <el-table-column prop="nickname" label="昵称" />
        <el-table-column prop="mobile" label="手机" />
        <el-table-column label="操作">
          <template #default="teachers">
            <el-button type="primary" size="mini" @click="edit(teachers.row)" :disabled="creating">编辑</el-button>
            <el-popconfirm :title="`确定删除讲师${teachers.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteItem(teachers.row.id)">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="所属机构" prop="organizationId">
          <el-select v-model="formData.organizationId">
            <el-option v-for="organization of organizations" :key="organization.id" :value="organization.id" :label="organization.name"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="讲师名称" prop="name">
          <el-input v-model="formData.name" placeholder="讲师姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="mobile">
          <el-input v-model="formData.mobile" placeholder="手机号"></el-input>
        </el-form-item>
        <el-form-item label="昵称" prop="nickname">
          <el-input v-model="formData.nickname" placeholder="昵称"></el-input>
        </el-form-item>
        <el-form-item label="讲师简介" prop="introduction">
          <el-input v-model="formData.introduction" placeholder="讲师简介"></el-input>
        </el-form-item>
        <el-form-item label="讲师头像">
          <el-upload :http-request="upload" :before-upload="beforeUpload" list-type="picture-card" :file-list="fileList" :on-remove="remove">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="讲师Banner">
          <el-upload :http-request="uploadBanner" :before-upload="beforeUpload" list-type="picture-card" :file-list="bannerList" :on-remove="remove">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item v-if="creating">
          <el-button @click="cancelAdd">取消</el-button>
          <el-button @click="add" type="primary">保存</el-button>
        </el-form-item>
        <el-form-item v-if="editing">
          <el-button @click="cancelEdit">取消</el-button>
          <el-button @click="update" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      organizations: [],
      creating: false,
      editing: false,
      formData: null,
      rules: {
        name: {
          required: true,
          message: '名称不允许为空',
          trigger: 'blur'
        },
        mobile: {
          required: true,
          message: '手机号不允许为空',
          trigger: 'blur'
        }
      },
      list: [],
      groups: [],
      file: null,
      fileList: [],
      bannerList: []
    }
  },
  methods: {
    getIndex: function (index) {
      return index === 0 ? 1 : ++index
    },
    loadData: function () {
      this.$axiosPlus.get('/teacher/all').then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
      this.$axiosPlus.get('/organization/all').then((result) => {
        this.organizations = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    create: function () {
      this.formData = {
        name: null,
        type: 1
      }
      this.creating = true
    },
    add: function () {
      this.$axiosPlus.post('/teacher',this.formData).then((result) => {
        this.list = result.data
        this.cancelAdd()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelAdd: function () {
      this.creating = false
      this.formData = null
    },
    deleteItem: function (id) {
      this.$axiosPlus.delete('/teacher/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.loadData()}).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (data) {
      this.formData = data
      if(data.avatar) {
        this.fileList = []
        this.fileList.push(
          {
            url: data.avatar
          }
        )
      }
      if(data.banner) {
        this.bannerList = []
        this.bannerList.push({
          url: data.banner
        })
      }
      this.editing = true
    },
    update: function () {
      this.$axiosPlus.put('/teacher',this.formData).then((result) => {
        this.list = result.data
        this.cancelEdit()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.fileList = []
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelEdit: function () {
      this.editing = false
      this.formData = null
    },
    upload: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/teacher/avatar/', 30).then((result) => {
          this.formData.avatarKey = result.path
          this.fileList = []
          this.fileList.push({
            url: result.url,
            name: upload.file.name
          })
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        });
      })
    },
    uploadBanner: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/teacher/banner/', 30).then((result) => {
          this.formData.bannerKey = result.path
          this.bannerList = []
          this.bannerList.push({
            url: result.url,
            name: upload.file.name
          })
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        });
      })
    },
    beforeUpload: function (file) {
      this.file = file
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
<style scoped>
.transfer {
  text-align: left;
}
</style>