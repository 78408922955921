<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-button :loading="creating" @click="create" :disabled="editing" type="primary">新建课程</el-button>
      </el-row>
      <el-table :data="list" height="80vh">
        <el-table-column type="index" :index="getIndex" label="序号" />
        <el-table-column prop="name" label="课程名称" />
        <el-table-column prop="actualPrice" label="实际售价" />
        <el-table-column prop="advertisingPrice" label="广告原价" />
        <el-table-column label="操作" :min-width="180">
          <template #default="lessons">
            <el-button type="primary" size="mini" @click="edit(lessons.row.id)" :disabled="creating">编辑</el-button>
            <el-button type="plain" size="mini" @click="editIntroduction(lessons.row.id)" :disabled="creating">简介</el-button>
            <el-button type="plain" size="mini" @click="editChapter(lessons.row.id)" :disabled="creating">章节</el-button>
            <el-popconfirm :title="`确定删除${lessons.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteItem(lessons.row.id)">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="课程名称" prop="name">
          <el-input v-model="formData.name" placeholder="课程名称"></el-input>
        </el-form-item>
        <el-form-item label="课程标签" prop="labels">
          <el-transfer v-model="formData.labels" class="transfer"
          :data="tags"
          :props="{
            key: 'id',
            label: 'name'
          }"
          filterable
          :titles="['未绑定', '已绑定']"
          :button-texts="['解绑', '绑定']"
          >
          </el-transfer>
        </el-form-item>
        <el-form-item label="课程封面" prop="cover">
          <el-image v-if="coverUrl" :src="coverUrl"></el-image>
          <el-upload :http-request="uploadCover" :before-upload="beforeUpload" :on-remove="remove" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="Banner" prop="banner">
          <el-image v-if="bannerUrl" :src="bannerUrl"></el-image>
          <el-upload :http-request="uploadBanner" :before-upload="beforeUpload" :on-remove="remove" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="实际售价" prop="actualPrice">
          <el-input-number v-model="formData.actualPrice" placeholder="实际售价" :min="0" precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="广告原价" prop="advertisingPrice">
          <el-input-number v-model="formData.advertisingPrice" placeholder="广告原价" :min="0" precision="2"></el-input-number>
        </el-form-item>
        <el-form-item label="排序权重" prop="sortIndex">
          <el-input-number v-model="formData.sortIndex" placeholder="排序权重"></el-input-number>
        </el-form-item>
        <el-form-item label="讲师" prop="teacherId">
          <el-select v-model="formData.teacherId">
            <el-option v-for="teacher of teachers" :key="teacher.value" :value="teacher.value" :label="teacher.label"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="creating">
          <el-button @click="cancelAdd">取消</el-button>
          <el-button @click="add" type="primary">保存</el-button>
        </el-form-item>
        <el-form-item v-if="editing">
          <el-button @click="cancelEdit">取消</el-button>
          <el-button @click="update" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
      <el-form v-if="editingIntroduction" :model="introductionForm" :rules="introductionRules">
        <template v-for="item, index of introductionForm.list" :key="index">
          <el-form-item label="类型" prop="type">
            <el-select v-model="item.type">
              <el-option :value="1" label="文本"></el-option>
              <el-option :value="2" label="标题"></el-option>
              <el-option :value="3" label="图片"></el-option>
              <!-- <el-option :value="4" label="多图"></el-option> -->
              <el-option :value="5" label="视频"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="内容" prop="content">
            <template v-if="item.type === 1">
              <el-input type="textarea" v-model="item.content" autosize></el-input>
            </template>
            <template v-if="item.type === 2">
              <el-input v-model="item.content"></el-input>
            </template>
            <template v-if="item.type === 3">
              <el-image v-if="item.url" :src="item.url"></el-image>
              <el-upload :http-request="uploadIntroductionMedia" :before-upload="beforeUpload" :data="index" :on-remove="remove" :show-file-list="false">
                <el-button type="primary" size="mini">上传</el-button>
              </el-upload>
            </template>
            <template v-if="item.type === 4">
              <el-input type="textarea" v-model="item.content" autosize></el-input>
            </template>
            <template v-if="item.type === 5">
              <video v-if="item.url" controls :src="item.url"></video>
              <el-upload :http-request="uploadIntroductionMedia" :before-upload="beforeUpload" :data="index" :on-remove="remove" :show-file-list="false">
                <el-button type="primary" size="mini" :loading="item.loading">上传</el-button>
              </el-upload>
            </template>
          </el-form-item>
          <el-form-item>
            <el-button @click="removePart(index)" type="danger" size="mini">删除板块</el-button>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-button @click="insertPart(index)" type="primary" size="mini">插入板块</el-button>
          </el-form-item>
          <el-divider></el-divider>
        </template>
        <el-form-ite v-if="introductionForm.list.length === 0">
          <el-button @click="addPart" type="primary" size="mini">添加板块</el-button>
        </el-form-ite>
        <el-form-item>
          <el-button @click="cancelEditIntroduction">取消</el-button>
          <el-button @click="saveIntroduction" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
      <el-form v-if="editingChapter">
        <template v-for="item, index of chapterForm.list" :key="index">
          <el-form-item label="名称">
            <el-input v-model="item.name"></el-input>
          </el-form-item>
          <el-form-item label="时长">
            <el-time-select format="mm:ss" start="00:01" end="59:59" step="00:01" min-time="00:00" max-time="60:00" v-model="item.track"></el-time-select>
          </el-form-item>
          <el-form-item label="试看设置">
            <el-switch active-text="可试看" inactive-text="需解锁" v-model="item.triable"></el-switch>
          </el-form-item>
          <el-form-item label="视频">
            <video v-if="item.url" controls :src="item.url"></video>
            <el-upload :http-request="uploadChapterMedia" :before-upload="beforeUpload" :data="index" :show-file-list="false">
              <el-button type="primary" size="mini" :loading="item.loading">上传</el-button>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button @click="removeChapter(index)" type="danger" size="mini">删除章节</el-button>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item>
            <el-button @click="insertChapter(index)" type="primary" size="mini">插入章节</el-button>
          </el-form-item>
          <el-divider></el-divider>
        </template>
        <el-form-item v-if="chapterForm.list.length === 0">
          <el-button @click="addChapter" type="primary" size="mini">添加章节</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancelEditChapter">取消</el-button>
          <el-button @click="saveChapter" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      creating: false,
      editing: false,
      editingIntroduction: false,
      editingChapter: false,
      formData: null,
      rules: {
        name: {
          required: true,
          message: '名称不允许为空',
          trigger: 'blur'
        },
        cover: {
          required: true,
          message: '封面不允许为空',
          trigger: 'blur'
        },
        actualPrice: {
          required: true,
          message: '实际售价不允许为空',
          trigger: 'blur'
        },
        advertisingPrice: {
          required: true,
          message: '广告原价不允许为空',
          trigger: 'blur'
        }
      },
      introductionForm: null,
      introductionRules: {
        type: {
          required: true,
          message: '类型不允许为空',
          trigger: 'blur'
        }
      },
      chapterForm: null,
      list: [],
      file: null,
      coverUrl: null,
      bannerUrl: null,
      teachers: [],
      tags: []
    }
  },
  methods: {
    getIndex: function (index) {
      return index === 0 ? 1 : ++index
    },
    loadData: function () {
      this.$axiosPlus.get('/lesson/all').then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    create: function () {
      this.formData = {
        name: null,
        cover: null,
        actualPrice: 0,
        advertisingPrice: 0,
        teacherId: null,
        labels: []
      }
      this.coverUrl = null
      this.bannerUrl = null
      this.creating = true
    },
    add: function () {
      this.$axiosPlus.post('/lesson',this.formData).then((result) => {
        this.list = result.data
        this.cancelAdd()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelAdd: function () {
      this.creating = false
      this.formData = null
    },
    deleteItem: function (id) {
      this.$axiosPlus.delete('/lesson/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.loadData()}).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (id) {
      this.$axiosPlus.get('/lesson/' + id).then(result => {
        this.formData = result.data
        this.coverUrl = result.data.coverUrl
        this.bannerUrl = result.data.bannerUrl
        this.editing = true
      }).catch(err => {
        console.log(err)
      })
    },
    update: function () {
      this.$axiosPlus.put('/lesson',this.formData).then((result) => {
        this.list = result.data
        this.cancelEdit()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
        this.fileList = []
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    editIntroduction: function (lessonId) {
      this.introductionForm = {
        lessonId: lessonId
      }
      this.$axiosPlus.get('/lesson/introduction/' + lessonId).then(result => {
        this.introductionForm.list = result.data
        this.editingIntroduction = true
      }).catch(err => {
        console.log(err)
      })
    },
    cancelEdit: function () {
      this.editing = false
      this.formData = null
    },
    removePart: function (index) {
      this.introductionForm.list.splice(index, 1)
    },
    insertPart: function (index) {
      this.introductionForm.list.splice(index + 1, 0 ,{
        type: 1,
        content: '',
        url: ''
      })
    },
    addPart: function () {
      this.introductionForm.list.push({
        type: 1,
        content: '',
        url: ''
      })
    },
    cancelEditIntroduction: function () {
      this.introductionForm = null
      this.file = null
      this.editingIntroduction = false
    },
    saveIntroduction: function () {
      this.$axiosPlus.put('/lesson/introduction/' + this.introductionForm.lessonId, this.introductionForm.list).then(() => {
        this.cancelEditIntroduction()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.fileList = []
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    uploadIntroductionMedia: function (upload) {
      this.introductionForm.list[upload.data].loading = true
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/lesson/introduction/', 30).then((result) => {
          this.introductionForm.list[upload.data].content = result.path
          this.introductionForm.list[upload.data].url = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          this.introductionForm.list[upload.data].loading = false
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    editChapter: function (lessonId) {
      this.chapterForm = {
        lessonId: lessonId
      }
      this.$axiosPlus.get('/lesson/chapter/' + lessonId).then(result => {
        this.chapterForm.list = result.data
        this.editingChapter = true
      }).catch(err => {
        console.log(err)
      })
    },
    removeChapter: function (index) {
      this.chapterForm.list.splice(index, 1)
    },
    insertChapter: function (index) {
      this.chapterForm.list.splice(index + 1, 0 ,{
        name: null,
        triable: false,
        source: null,
        url: null,
        track: '00:01'
      })
    },
    addChapter: function () {
      this.chapterForm.list.push({
        name: null,
        triable: false,
        source: null,
        url: null,
        track: '00:01'
      })
    },
    cancelEditChapter: function () {
      this.chapterForm = null
      this.file = null
      this.editingChapter = false
    },
    saveChapter: function () {
      this.$axiosPlus.put('/lesson/chapter/' + this.chapterForm.lessonId, this.chapterForm.list).then(() => {
        this.cancelEditChapter()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
        this.fileList = []
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    uploadChapterMedia: function (upload) {
      this.chapterForm.list[upload.data].loading = true
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/lesson/chapter/' + this.chapterForm.lessonId + '/', 30).then((result) => {
          this.chapterForm.list[upload.data].source = result.path
          this.chapterForm.list[upload.data].url = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          this.chapterForm.list[upload.data].loading = false
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    uploadCover: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/lesson/cover/', 30).then((result) => {
          this.formData.cover = result.path
          this.coverUrl = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    uploadBanner: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/lesson/banner/', 30).then((result) => {
          this.formData.banner = result.path
          this.bannerUrl = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    beforeUpload: function (file) {
      this.file = file
    }
  },
  mounted: function () {
    new Promise((resolve, reject) => {
      this.$axiosPlus.get('/teacher/all').then(result => {
        result.data.forEach(teacher => {
          this.teachers.push({
            value: teacher.id,
            label: teacher.name
          })
        })
        resolve()
      }).catch(err => {
        console.log(err)
        reject()
      })
    }).then(() => {
      new Promise((resolve, reject) => {
        this.$axiosPlus.get('/lesson/tag/all').then(result => {
          this.tags = result.data
          resolve()
        }).catch(err => {
          console.log(err)
          reject()
        })
      }).then(() => {
        this.loadData()
      })
    })
  }
}
</script>
<style scoped>
.transfer {
  text-align: left;
}
</style>