import { createRouter, createWebHashHistory } from 'vue-router'
import index from '@/components/Index'
import login from '@/components/Login'
import dashboard from '@/components/Dashboard'
import quotation from '@/components/Quotation'
import category from '@/components/Category'
import categoryGroup from '@/components/CategoryGroup'
import market from '@/components/Market'
import lessonTagGroup from '@/components/LessonTagGroup'
import lessonTag from '@/components/LessonTag'
import organization from '@/components/Organization'
import teacher from '@/components/Teacher'
import contactReport from '@/components/ContactReport'
import categoryContactReport from '@/components/CategoryContactReport'
import paymentReport from '@/components/PaymentReport'
import lessonChapterVisitReport from '@/components/LessonChapterVisitReport'
import lesson from '@/components/Lesson'
import pestsAndDiseasesCategory from '@/components/PestsAndDiseasesCategory'
import pestsAndDiseases from '@/components/PestsAndDiseases'
import plantProtectionArticle from '@/components/PlantProtectionArticle'
import MarketingHotSellingLesson from '@/components/MarketingHotSellingLesson'
import Marketing from '@/components/Marketing'
import dev from '@/components/Dev'

const routes = [
  {
    path: '/',
    component: login
  },
  {
    path: '/index',
    component: index,
    children: [
      {
        path: '',
        component: dashboard
      },
      {
        path: 'dashboard',
        component: dashboard
      },
      {
        path: 'quotation',
        component: quotation
      },
      {
        path: 'market',
        component: market
      },
      {
        path: 'category',
        component: category
      },
      {
        path: 'category-group',
        component: categoryGroup
      },
      {
        path: 'lessonTagGroup',
        component: lessonTagGroup
      },
      {
        path: 'lessonTag',
        component: lessonTag
      },
      {
        path: 'organization',
        component: organization
      },
      {
        path: 'teacher',
        component: teacher
      },
      {
        path: 'lesson',
        component: lesson
      },
      {
        path: 'pestsAndDiseasesCategory',
        component: pestsAndDiseasesCategory
      },
      {
        path: 'pestsAndDiseases',
        component: pestsAndDiseases
      },
      {
        path: 'plantProtectionArticle',
        component: plantProtectionArticle
      },
      {
        path: 'contactReport',
        component: contactReport
      },
      {
        path: 'categoryContactReport',
        component: categoryContactReport
      },
      {
        path: 'paymentReport',
        component: paymentReport
      },
      {
        path: 'lessonChapterVisitReport',
        component: lessonChapterVisitReport
      },
      {
        path: 'marketingHotSellingLesson',
        component: MarketingHotSellingLesson
      },
      {
        path: 'marketing',
        component: Marketing
      },
      {
        path: 'dev',
        component: dev
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default (app) => {
  app.use(router)
}