import { createApp } from 'vue'
import App from './App.vue'
import router from './config/routerConfig'
import store from './config/storeConfig'
import axios from './config/axiosConfig'
import axiosPlus from './config/axiosPlusConfig'
import elementPlus from './config/elementPlusConfig'
import cosHelper from './config/cosConfig'

const app = createApp(App)
router(app)
store(app)
axios(app)
axiosPlus(app)
elementPlus(app)
cosHelper(app)
app.mount('#app')