import axios from 'axios'

const instance = axios.create({
  baseURL: process.env.VUE_APP_SERVER_URL
})
instance.interceptors.request.use(function(config){
  config.headers['tsnf-token']= window.sessionStorage.getItem('token')
  return config
})

export default {
  demo: function () {
    console.log('axiosPlus works')
  },
  get: function (url, config) {
    return instance.get(url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  post: function (url, data, config) {
    return instance.post(url, data, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  put: function (url, data, config) {
    return instance.put(url, data, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  delete: function (url, config) {
    return instance.delete(process.env.VUE_APP_SERVER_URL + url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  head: function (url, config) {
    return instance.head(process.env.VUE_APP_SERVER_URL + url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  options: function (url, config) {
    return instance.options(process.env.VUE_APP_SERVER_URL + url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  patch: function (url, data, config) {
    return instance.patch(process.env.VUE_APP_SERVER_URL + url, data, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  originalGet: function (url, config) {
    return axios.get(url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  originalPost: function (url, data, config) {
    return axios.post(url, data, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  originalPut: function (url, data, config) {
    return axios.put(url, data, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  },
  originalDelete: function (url, config) {
    return axios.delete(url, config).then((result) => {
      return Promise.resolve(result.data)
    }).catch((err) => {
      return Promise.reject(err.response.data)
    })
  }
}