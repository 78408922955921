<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-button :loading="creating" @click="create" :disabled="editing" type="primary">新建文章</el-button>
      </el-row>
      <el-table :data="list" height="80vh">
        <el-table-column type="index" :index="getIndex" label="序号" />
        <el-table-column prop="name" label="文章名称" />
        <el-table-column prop="publishTime" label="发布时间" />
        <el-table-column label="操作" :min-width="180">
          <template #default="articles">
            <el-button type="primary" size="mini" @click="edit(articles.row.id)" :disabled="creating">编辑</el-button>
            <el-popconfirm :title="`确定删除${articles.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteItem(articles.row.id)">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="文章名称" prop="name">
          <el-input v-model="formData.name" placeholder="文章名称"></el-input>
        </el-form-item>
        <el-form-item label="文章作者" prop="author">
          <el-input v-model="formData.author" placeholder="文章作者"></el-input>
        </el-form-item>
        <el-form-item label="排序权重" prop="sortIndex">
          <el-input-number v-model="formData.sortIndex" placeholder="排序权重"></el-input-number>
        </el-form-item>
        <el-form-item label="文章封面">
          <div class="covers">
            <div v-for="(cover, index) of formData.covers" :key="index" class="cover">
              <div class="coverImage">
                <el-image :src="cover.url"></el-image>
              </div>
              <div><el-button @click="removeCover(index)">删除</el-button></div>
            </div>
          </div>
          <el-upload :http-request="uploadCover" :before-upload="beforeUpload" :show-file-list="false">
            <el-button type="primary">上传封面</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="文章内容">
          <template v-for="(content, index) of formData.contents" :key="index">
            <div><el-button @click="insertPart(index)">插入版块</el-button></div>
            <el-divider></el-divider>
            <div>
              <el-select v-model="content.type">
                <el-option :value="6" label="一级标题"></el-option>
                <el-option :value="7" label="二级标题"></el-option>
                <el-option :value="8" label="三级标题"></el-option>
                <el-option :value="1" label="文本"></el-option>
                <el-option :value="3" label="图片"></el-option>
                <el-option :value="5" label="视频"></el-option>
              </el-select>
            </div>
            <div>
              <template v-if="content.type === 1">
                <el-input type="textarea" v-model="content.content" autosize></el-input>
              </template>
              <template v-if="content.type === 6 || content.type === 7 || content.type === 8">
                <el-input v-model="content.content"></el-input>
              </template>
              <template v-if="content.type === 3">
                <el-image v-if="content.url" :src="content.url"></el-image>
                <el-upload :http-request="uploadMedia" :before-upload="beforeUpload" :data="index" :show-file-list="false">
                  <el-button type="primary" size="mini">上传</el-button>
                </el-upload>
              </template>
              <template v-if="content.type === 5">
                <video v-if="content.url" controls :src="content.url"></video>
                <el-upload :http-request="uploadMedia" :before-upload="beforeUpload" :data="index" :show-file-list="false">
                  <el-button type="primary" size="mini" :loading="content.loading">上传</el-button>
                </el-upload>
              </template>
            </div>
            <div>
              <el-button @click="removePart(index)">移除版块</el-button>
            </div>
            <el-divider></el-divider>
          </template>
          <div><el-button @click="appendPart">添加版块</el-button></div>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancel">取消</el-button>
          <el-button @click="add" type="primary" v-if="creating">保存</el-button>
          <el-button @click="update" type="primary" v-if="editing">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      creating: false,
      editing: false,
      formData: null,
      rules: {
        name: {
          required: true,
          message: '名称不允许为空',
          trigger: 'blur'
        }
      },
      list: [],
      file: null
    }
  },
  methods: {
    getIndex: function (index) {
      return index === 0 ? 1 : ++index
    },
    loadData: function () {
      this.$axiosPlus.get('/plantProtectionArticle/all').then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    create: function () {
      this.formData = {
        name: null,
        sortIndex: 0,
        covers: [],
        contents: []
      }
      this.creating = true
    },
    add: function () {
      this.$axiosPlus.post('/plantProtectionArticle',this.formData).then((result) => {
        this.list = result.data
        this.cancel()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancel: function () {
      this.creating = false
      this.editing = false
      this.formData = null
    },
    deleteItem: function (id) {
      this.$axiosPlus.delete('/plantProtectionArticle/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (id) {
      this.$axiosPlus.get('/plantProtectionArticle/detail/' + id).then(result => {
        this.formData = result.data
        this.editing = true
      }).catch(err => {
        console.log(err)
      })
    },
    update: function () {
      this.$axiosPlus.put('/plantProtectionArticle',this.formData).then((result) => {
        this.list = result.data
        this.cancel()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    uploadCover: function (upload) {
      this.$cosHelper.upload (upload, this.file, '/article/cover/', 30).then((result) => {
        this.formData.covers.push({
          path: result.path,
          url: result.url
        })
        this.$message({
          message: result.name + '上传成功',
          type: 'success'
        })
      }).catch((err) => {
        this.$message.error(err + '上传失败')
      })
    },
    removeCover: function (index) {
      this.formData.covers.splice(index, 1)
    },
    removePart: function (index) {
      this.formData.contents.splice(index, 1)
    },
    insertPart: function (index) {
      this.formData.contents.splice(index, 0 ,{
        type: 1,
        content: '',
        url: ''
      })
    },
    appendPart: function () {
      this.formData.contents.push({
        type: 1,
        content: '',
        url: ''
      })
    },
    uploadMedia: function (upload) {
      this.formData.contents[upload.data].loading = true
      this.$cosHelper.upload (upload, this.file, '/article/media/', 30).then((result) => {
        this.formData.contents[upload.data].content = result.path
        this.formData.contents[upload.data].url = result.url
        this.$message({
          message: result.name + '上传成功',
          type: 'success'
        })
        this.formData.contents[upload.data].loading = false
      }).catch((err) => {
        this.$message.error(err + '上传失败')
      })
    },
    beforeUpload: function (file) {
      this.file = file
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
<style scoped>
.transfer {
  text-align: left;
}

.covers {
  display: flex
}

.cover {
  width: 6vw;
  padding: 5px;
}
</style>