<template>
  <div>
    Labrary
    <div style="width: 20vw;">
      <el-upload :http-request="upload" multiple :before-upload="beforeUpload" list-type="picture-card" :on-remove="remove">
        <el-button type="primary">upload</el-button>
      </el-upload>
      {{fileList}}
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      file: null,
      fileList: []
    }
  },
  methods: {
    upload: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/test/file/', 30).then((result) => {
          this.fileList.push({
            name: upload.file.name,
            path: result.path
          })
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        });
      })
    },
    beforeUpload: function (file) {
      this.file = file
    },
    remove: function (file, fileList) {
      console.log(fileList)
      this.fileList.forEach((item, index, list) => {
        if (item.name === file.name) {
          list.splice(index, 1)
        }
      })
    }
  }
}
</script>
<style scoped>

</style>