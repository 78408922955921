<template>
  <el-row>
    <el-col :span="24">
      <div class="blue">云菜易通小程序后台管理系统</div>
    </el-col>
  </el-row>
  <el-row>
    <el-col :span="4">
      <el-menu router :default-active="$router.path" :unique-opened="true">
        <el-menu-item index="/index/dashboard">数据看版</el-menu-item>
        <el-submenu index="quotation">
          <template #title>市场行情</template>
          <el-menu-item index="/index/quotation">行情填报</el-menu-item>
          <el-menu-item index="/index/market">市场管理</el-menu-item>
          <el-menu-item index="/index/category-group">品种分组</el-menu-item>
          <el-menu-item index="/index/category">品种管理</el-menu-item>
        </el-submenu>
        <el-submenu index="lesson">
          <template #title>学堂管理</template>
          <el-menu-item index="/index/lessonTagGroup">标签分组</el-menu-item>
          <el-menu-item index="/index/lessonTag">标签管理</el-menu-item>
          <el-menu-item index="/index/organization">机构管理</el-menu-item>
          <el-menu-item index="/index/teacher">讲师管理</el-menu-item>
          <el-menu-item index="/index/lesson">课程管理</el-menu-item>
        </el-submenu>
        <el-submenu index="wiki">
          <template #title>虫害百科</template>
          <el-menu-item index="/index/pestsAndDiseasesCategory">词条分类</el-menu-item>
          <el-menu-item index="/index/pestsAndDiseases">词条管理</el-menu-item>
        </el-submenu>
        <el-submenu index="article">
          <template #title>植保文章</template>
          <el-menu-item index="/index/plantProtectionArticle">文章管理</el-menu-item>
        </el-submenu>
        <el-submenu index="marketing">
          <template #title>营销管理</template>
          <el-menu-item index="/index/marketingHotSellingLesson">热销课程</el-menu-item>
        </el-submenu>
        <el-submenu index="report">
          <template #title>统计报表</template>
          <el-menu-item index="/index/paymentReport">支付报表</el-menu-item>
          <el-menu-item index="/index/lessonChapterVisitReport">课程访问</el-menu-item>
          <el-menu-item index="/index/contactReport">电话获取</el-menu-item>
          <el-menu-item index="/index/categoryContactReport">品种热度</el-menu-item>
        </el-submenu>
        <el-submenu index="labrary" v-if="env === 'development'">
          <template #title>Labrary</template>
          <el-menu-item index="/index/dev">文件上传</el-menu-item>
          <el-menu-item index="/index/marketing">营销管理</el-menu-item>
        </el-submenu>
      </el-menu>
    </el-col>
    <el-col :span="20"><router-view></router-view></el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      env: process.env.NODE_ENV
    }
  },
  mounted: function () {
    const token = sessionStorage.getItem('token')
    if (token) {
      console.log()
    } else {
      this.$router.push('/')
    }
  }
}
</script>
<style scoped>
.blue {
  font-size: 4vh;
  height: 5vh;
  line-height: 5vh;
}
</style>