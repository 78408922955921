<template>
  <el-row :gutter="20">
    <el-col :span="5">
      开始日期<el-date-picker v-model="beginTime" type="date" placeholder="选择起始日期"></el-date-picker>
    </el-col>
    <el-col :span="5">
      结束日期<el-date-picker v-model="endTime" type="date" placeholder="选择结束日期"></el-date-picker>
    </el-col>
    <el-col :span="5">
      课程名称
      <el-select v-model="lessonId" placeholder="选择课程名称" clearable>
        <el-option v-for="lesson of lessons" :key="lesson.value" :value="lesson.id" :label="lesson.name"></el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-button type="primary" @click="loadData">查询</el-button>
    </el-col>
  </el-row>
  <el-row>
    <el-table height="80vh" :data="list" stripe highlight-current-row>
      <el-table-column type="index" label="序号" width="60"></el-table-column>
      <el-table-column prop="lessonName" label="课程名称" width="180"></el-table-column>
      <el-table-column prop="chapterName" label="章节名称" width="180"></el-table-column>
      <el-table-column prop="visitTime" label="访问时间" width="180"></el-table-column>
    </el-table>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      beginTime: null,
      endTime: null,
      lessons: [],
      lessonId: null,
      list: []
    }
  },
  methods: {
    loadData: function () {
      let params = {}
      if (this.beginTime) {
        params.beginTime = this.beginTime
      }
      if (this.endTime) {
        params.endTime = this.endTime
      }
      if (this.lessonId >= 0) {
        params.lessonId = this.lessonId
      }
      this.$axiosPlus.get('/report/lessonChapterVisitLog', {params: params}).then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    }
  },
  mounted: function () {
    let now = new Date()
    this.beginTime = now
    this.endTime = now
    this.loadData()
    this.$axiosPlus.get('/lesson/all').then((result)=>{
      this.lessons = result.data
    })
  }
}
</script>
<style scoped>

</style>