import vuex from 'vuex'
import demo from '../store/demo/store'

const store = vuex.createStore({
  modules: {
    demo
  }
})

export default (app) => {
  app.use(store)
}