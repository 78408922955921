<template>
  <div>
    <div class="condition">
      <div class="condition-item">
        <el-date-picker
          v-model="date"
          :clearable="false"
          align="right"
          type="date"
          placeholder="选择日期"
          >
        </el-date-picker>
      </div>
      <div class="condition-item">
        <el-select v-model="market.value" filterable @change="changeMarket">
          <el-option
            v-for="option in market.options"
            :key="option.value"
            :label="option.label"
            :value="option.value"
          >
          </el-option>
        </el-select>
      </div>
      <div class="condition-item">
        <el-button type="primary" :disabled="maintenance" plain @click="commit">保存</el-button>
      </div>
      <div class="condition-item">
        <el-switch v-model="maintenance"
        active-text="维护模式"
        inactive-text="填报模式"
        inactive-color="#409EFF"
        @change="changeMode"
        ></el-switch>
      </div>
      <div class="condition-item">
        <el-button type="primary" :disabled="!maintenance" plain @click="query">查询</el-button>
      </div>
    </div>
    <div>
      <el-table :data="data" height="80vh">
        <el-table-column label="品种名称" prop="label" width="150" header-align="center"></el-table-column>
        <el-table-column label="价格" width="200" header-align="center">
          <template #default="scope">
            <el-input-number v-model="scope.row.price" :min="0" :precision="2" :step="0.01" step-strictly></el-input-number>
          </template>
        </el-table-column>
        <el-table-column label="操作" width="200" header-align="center" v-if="maintenance">
          <template #default="scope">
            <el-button @click="update(scope.row)">更新</el-button>
            <el-popconfirm :title="`确定删除${scope.row.label}的数据？`" confirm-button-text="删除" cancel-button-text="取消" @confirm="deleteData(scope.row.id)">
              <template #reference>
                <el-button type="danger">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
export default {
  data: function () {
    return {
      date: null,
      maintenance: false,
      data: [],
      market: {
        options: [
          {
            value: 1,
            label: '晋宁产区'
          },
          {
            value: 2,
            label: '陆良产区'
          },
          {
            value: 3,
            label: '嵩明产区'
          },
          {
            value: 4,
            label: '呈贡产区'
          },
          {
            value: 5,
            label: '广州市场'
          },
          {
            value: 6,
            label: '深圳海吉星市场'
          },
          {
            value: 7,
            label: '虎门富民'
          },
          {
            value: 8,
            label: '长沙海吉星'
          },
          {
            value: 9,
            label: '南宁海吉星'
          },
          {
            value: 10,
            label: '厦门同安'
          },
          {
            value: 11,
            label: '武汉四季美'
          },
          {
            value: 12,
            label: '郑州万邦市场'
          },
          {
            value: 13,
            label: '成都雨润市场'
          },
          {
            value: 14,
            label: '上海江桥市场'
          }      
        ],
        value: 1
      },
    }
  },
  methods: {
    deleteData: function (id) {
      this.$axiosPlus.delete('/quotation/' + id).then(() => {
        this.query()
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      })
    },
    update: function (data) {
      if (data.price > 0) {
        this.$axiosPlus.put('/quotation', {
          id: data.id,
          price: data.price
        }).then(
          () => {
            this.query()
            this.$message({
              message: '修改成功',
              type: 'success'
            })
          }
        )
      } else {
        this.$notify.error({
          title: '价格不允许为零'
        })
      }
    },
    changeMode: function () {
      if (this.maintenance) {
        this.data = []
      } else {
        this.changeMarket()
      }
    },
    changeMarket: function () {
      this.loadFillableCategories(this.market.value)
    },
    loadFillableCategories: function (marketId) {
      this.$axiosPlus.get('/quotation/fillable/' + marketId).then((result)=>{
        this.data = []
        result.data.forEach(category => {
          this.data.push({
            indexShow: false,
            price: 0,
            value: category.id,
            label: category.name
          })
        })
      }).catch((error)=>{
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })
      })
    },
    query: function () {
      this.$axiosPlus.get('quotation/list', {
        params: {
          date: this.formatDateString,
          marketId: this.market.value
        }
      }).then(result => {
        this.data = []
        for(const item of result.data) {
          this.data.push({
            id: item.id,
            price: item.price,
            label: item.categoryName
          })
        }

        console.log(result.data)
      })
    },
    commit: function () {
      if (this.data) {
        const postData = {}
        postData.date = this.formatDateString
        postData.marketId = this.market.value
        postData.data = []
        for (const quotation of this.data) {
          const data = {}
          data.price = quotation.price
          data.categoryId = quotation.value
          data.showOnIndex = quotation.indexShow
          if (data.price > 0) {
            postData.data.push(data)
          }
        }
        if (postData.data.length > 0) {
          this.$axiosPlus.post('/quotation/batch',postData).then(()=>{
            for (const quotation of this.data) {
              if (quotation.price > 0) {
                quotation.price = 0
              }
            }
            this.$message({
              message: '保存成功',
              type: 'success'
            })
          }).catch((error)=>{
            this.$message({
              message: error.code + ':' + error.message,
              type: 'error'
            })
          })
        } else {
          this.$notify.error({
            title: '价格全部为零',
            message: '请填写至少一个价格'
          })
        }
      }
    }
  },
  mounted: function () {
    this.date = new Date()
    this.$axiosPlus.get('/market/list').then((result)=>{
      this.market.options = []
      result.data.forEach(market => {
        this.market.options.push({
          value: market.id,
          label: market.name
        })
      })
      this.loadFillableCategories(this.market.value)
    }).catch((error)=>{
      this.$message({
        message: error.code + ':' + error.message,
        type: 'error'
      })
    })
  },
  computed: {
    formatDateString: function () {
      return this.date.getUTCFullYear() + '-' + (this.date.getUTCMonth() + 1) + '-' + (this.date.getUTCDate() + (this.date.getUTCHours() < 16 ? 0 : 1)) + ' 00:00:00'
    }
  }
}
</script>
<style scoped>
.condition {
  display: flex;
  margin-top: 5vh;
}

.condition-item {
  margin-left: 8px;
}
</style>