<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-select v-model="category" placeholder="选择分类" @change="loadList">
          <el-option v-for="item of categories" :key="item.id" :label="item.name" :value="item.id"></el-option>
        </el-select>
      </el-row>
      <el-row align="left">
        <el-button v-if="category" :loading="creating" @click="create" type="primary">新建病虫害</el-button>
      </el-row>
      <el-table :data="list" height="80vh">
        <el-table-column type="index" :index="getIndex" label="序号" />
        <el-table-column prop="name" label="名称" />
        <el-table-column label="操作">
          <template #default="categories">
            <el-button type="primary" size="mini" @click="edit(categories.row)">编辑</el-button>
            <el-button type="primary" size="mini" @click="editDetails(categories.row.id)">编辑词条</el-button>
            <el-popconfirm :title="`确定删除${categories.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteWiki(categories.row.id)">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="名称" prop="name">
          <el-input v-model="formData.name" placeholder="名称"></el-input>
        </el-form-item>
        <el-form-item label="权重" prop="sortIndex">
          <el-input-number v-model="formData.sortIndex" placeholder="权重"></el-input-number>
        </el-form-item>
        <el-form-item v-if="creating">
          <el-button @click="cancelAdd">取消</el-button>
          <el-button @click="add" type="primary">保存</el-button>
        </el-form-item>
        <el-form-item v-if="editing">
          <el-button @click="cancelEdit">取消</el-button>
          <el-button @click="update" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
      <el-form v-if="detailForm" :model="formData" :rules="rules">
        <template v-for="(chapter, index) of detailForm.chapters" :key="index">
          <el-form-item label="章节名称">
            <el-input v-model="chapter.name"></el-input>
          </el-form-item>
          <el-form-item v-if="chapter.contents.length === 0">
            <el-button @click="appendContent(index, 0)" type="primary" size="mini">创建版块</el-button>
          </el-form-item>
          <template v-for="(content, contentIndex) of chapter.contents" :key="contentIndex">
            <el-form-item label="类型">
              <el-select v-model="content.type">
                <el-option label="文本" :value="1"></el-option>
                <el-option label="图片" :value="3"></el-option>
                <el-option label="视频" :value="5"></el-option>
              </el-select>              
            </el-form-item>
            <el-form-item v-if="content.type === 1" label="文本内容">
              <el-input v-model="content.content" autosize type="textarea"></el-input>
            </el-form-item>
            <el-form-item v-if="content.type === 3" label="图片">
              <el-image v-if="content.url" :src="content.url"></el-image>
              <el-upload :http-request="uploadMedia" :before-upload="beforeUpload" :data="content" :show-file-list="false">
                <el-button type="primary" size="mini">上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item v-if="content.type === 5" label="视频">
              <video v-if="content.url" controls :src="content.url"></video>
              <el-upload :http-request="uploadMedia" :before-upload="beforeUpload" :data="content" :show-file-list="false">
                <el-button type="primary" size="mini" :loading="content.loading">上传</el-button>
              </el-upload>
            </el-form-item>
            <el-form-item>
              <el-button @click="appendContent(index, contentIndex)" type="primary" size="mini">插入版块</el-button>
            </el-form-item>
            <el-form-item>
              <el-button @click="removeContent(index, contentIndex)" type="danger" size="mini">移除版块</el-button>
            </el-form-item>
          </template>
          <el-form-item>
            <el-button @click="appendChapter(index)" type="primary" size="small">插入章节</el-button>
          </el-form-item>
          <el-form-item>
            <el-button @click="removeChapter(index)" type="danger" size="small">移除章节</el-button>
          </el-form-item>
          <el-divider></el-divider>
        </template>
        <el-form-item v-if="detailForm.chapters.length === 0">
          <el-button @click="appendChapter" type="primary">创建章节</el-button>
        </el-form-item>
        <el-form-item>
          <el-button @click="cancelEditDetail">取消</el-button>
          <el-button @click="saveDetail" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      categories: [],
      category: null,
      creating: false,
      editing: false,
      formData: null,
      detailForm: null,
      file: null,
      rules: {
        name: {
          required: true,
          message: '名称不允许为空',
          trigger: 'blur'
        },
        sortIndex: {
          required: true,
          message: '排序权重不允许为空',
          trigger: 'blur'
        }
      },
      list: []
    }
  },
  methods: {
    getIndex: function (index) {
      return index === 0 ? 1 : ++index
    },
    loadList: function () {
      this.$axiosPlus.get('/wiki/pestsAndDiseases/' + this.category ).then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    loadData: function () {
      this.$axiosPlus.get('/wiki/pestsAndDiseases/categories').then((result) => {
        this.categories = result.data
        if (this.categories && this.categories.length > 0) {
          this.category = this.categories[0].id
          this.loadList()
        }
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    create: function () {
      this.detailForm = null
      this.formData = {
        name: null,
        sortIndex: 0,
        categoryId: this.category
      }
      this.creating = true
    },
    add: function () {
      this.$axiosPlus.post('/wiki/pestsAndDiseases',this.formData).then((result) => {
        this.list = result.data
        this.cancelAdd()
        this.$message({
          message: '保存成功',
          type: 'success'
        })        
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelAdd: function () {
      this.creating = false
      this.formData = null
    },
    deleteWiki: function (id) {
      this.$axiosPlus.delete('/wiki/pestsAndDiseases/' + this.category + '/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (data) {
      this.detailForm = null
      this.formData = data
      this.editing = true
    },
    update: function () {
      this.$axiosPlus.put('/wiki/pestsAndDiseases',this.formData).then((result) => {
        this.list = result.data
        this.cancelEdit()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelEdit: function () {
      this.editing = false
      this.formData = null
    },
    editDetails: function (id) {
      this.$axiosPlus.get('/wiki/pestsAndDiseases/details/' + id).then((result) => {
        this.detailForm = {
          id: id,
          chapters: result.data
        }
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelEditDetail: function () {
      this.detailForm = null
    },
    saveDetail: function () {
      this.$axiosPlus.post('/wiki/pestsAndDiseases/details', this.detailForm).then(() => {
        this.detailForm = null
        this.$message({
          message: '保存成功',
          type: 'success'
        })  
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    beforeUpload: function (file) {
      this.file = file
    },
    uploadMedia: function (upload) {
      return new Promise ((resolve, reject) => {
        upload.data.loading = true
        this.$cosHelper.upload (upload, this.file, '/wiki/', 30).then((result) => {
          console.log(upload.data)
          upload.data.content = result.path
          upload.data.url = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          upload.data.loading = false
          resolve()
        }).catch((err) => {
          upload.data.loading = false
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    appendChapter: function (index) {
      this.detailForm.chapters.splice(index + 1, 0, {
        name: '',
        contents: []
      })
    },
    removeChapter: function (index) {
      this.detailForm.chapters.splice(index, 1)
    },
    appendContent: function (index, contentIndex) {
      this.detailForm.chapters[index].contents.splice(contentIndex + 1, 0, {
        type: 1,
        content: '',
        url: ''
      })
    },
    removeContent: function (index, contentIndex) {
      this.detailForm.chapters[index].contents.splice(contentIndex, 1)
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
<style scoped>

</style>