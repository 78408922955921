<template>
  <el-row :gutter="20">
    <el-col :span="5">
      日期<el-date-picker v-model="date" type="date" placeholder="选择日期" :default-value="now"></el-date-picker>
    </el-col>
    <el-col :span="4">
      <el-button type="primary" @click="loadData">查询</el-button>
    </el-col>
  </el-row>
  <el-row>
    <el-table height="80vh" :data="list" show-summary stripe highlight-current-row>
      <el-table-column type="index" label="计数" width="50"></el-table-column>
      <el-table-column prop="nickname" label="用户昵称" width="180"></el-table-column>
      <el-table-column prop="avatar" label="用户头像" width="100">
        <template #default="scope">
          <div style="width: 40px; height: 40px;">
            <el-image :src="scope.row.avatar"></el-image>
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="mobile" label="手机号" width="180"></el-table-column>
      <el-table-column prop="times" label="获取次数" width="180"></el-table-column>
    </el-table>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      date: null,
      list: []
    }
  },
  methods: {
    loadData: function () {
      console.log(this.date)
      this.$axiosPlus.get('/report/contactGetLog', {
        params: {
          date: this.date
        }
      }).then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    }
  },
  mounted: function () {
    this.date = new Date()
    this.loadData()
  }
}
</script>
<style scoped>

</style>