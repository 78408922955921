<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="center">
        <h3>静态图</h3>
        <el-table :data="images" height="80vh">
          <el-table-column label="图片">
            <template #default="scope">
              <el-upload v-if="scope.row.url" :disabled="!scope.row.editing" :http-request="upload" :before-upload="beforeUpload" :data="scope.row" :show-file-list="false">
              <div class="image" >
                <el-image fit="contain" :src="scope.row.url"></el-image>
              </div>
              </el-upload>
              <el-upload v-else :http-request="upload" :before-upload="beforeUpload" :data="scope.row" :show-file-list="false">
                <el-button type="primary" :disabled="!scope.row.editing">上传</el-button>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="目标指向">
            <template #default="scope">
              <el-select v-model="scope.row.target" :disabled="!scope.row.editing" filterable>
                <el-option v-for="target of targets" :key="target.value" :label="target.label" :value="target.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="排序权重">
            <template #default="scope">
              <el-input-number v-model="scope.row.sortIndex" :disabled="!scope.row.editing"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="primary" size="mini" v-if="scope.row.editing" @click="save(scope.row)">保存</el-button>
              <el-button type="plain" size="mini" v-if="scope.row.editing" @click="cancel(scope.row)">取消</el-button>
              <el-button v-if="!scope.row.editing" type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-popconfirm v-if="!scope.row.editing" :title="`确定删除？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="reomveImage(scope)">
                <template #reference>
                  <el-button type="danger" size="mini">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="full-button" type="primary" @click="addImage">添加</el-button>
      </el-row>
    </el-col>
    <el-col :span="12">
      <el-row align="center">
        <h3>轮播图</h3>
        <el-table :data="carousels" height="80vh">
          <el-table-column label="图片">
            <template #default="scope">
              <el-upload v-if="scope.row.url" :disabled="!scope.row.editing" :http-request="upload" :before-upload="beforeUpload" :data="scope.row" :show-file-list="false">
              <div class="image" >
                <el-image fit="contain" :src="scope.row.url"></el-image>
              </div>
              </el-upload>
              <el-upload v-else :http-request="upload" :before-upload="beforeUpload" :data="scope.row" :show-file-list="false">
                <el-button type="primary" :disabled="!scope.row.editing">上传</el-button>
              </el-upload>
            </template>
          </el-table-column>
          <el-table-column label="目标指向">
            <template #default="scope">
              <el-select v-model="scope.row.target" :disabled="!scope.row.editing" filterable>
                <el-option v-for="target of targets" :key="target.value" :label="target.label" :value="target.value"></el-option>
              </el-select>
            </template>
          </el-table-column>
          <el-table-column label="排序权重">
            <template #default="scope">
              <el-input-number v-model="scope.row.sortIndex" :disabled="!scope.row.editing"></el-input-number>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template #default="scope">
              <el-button type="primary" size="mini" v-if="scope.row.editing" @click="save(scope.row)">保存</el-button>
              <el-button type="plain" size="mini" v-if="scope.row.editing" @click="cancel(scope.row)">取消</el-button>
              <el-button v-if="!scope.row.editing" type="primary" size="mini" @click="edit(scope.row)">编辑</el-button>
              <el-popconfirm v-if="!scope.row.editing" :title="`确定删除？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="reomveCarousel(scope)">
                <template #reference>
                  <el-button type="danger" size="mini">删除</el-button>
                </template>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>
        <el-button class="full-button" type="primary" @click="addCarousel">添加</el-button>
      </el-row>
    </el-col>
  </el-row>
</template>
<script>
const usageType = 1
const targetType = 'lessons'


export default {
  data: function () {
    return {
      file: null,
      images: [],
      carousels: [],
      targets: []
    }
  },
  methods: {
    loadData: function () {
      this.$axiosPlus.get('/marketing/banner/' + usageType).then((result) => {
        this.images = result.data.images
        this.carousels = result.data.carousels
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
      this.$axiosPlus.get('/marketing/target/' + targetType).then((result) => {
        this.targets = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (data)  {
      data.editing = true
    },
    addImage: function () {
      this.images.push({
        url: null,
        target: null,
        editing: true,
        type: 1,
        usageType: usageType,
        sortIndex: 0
      })
    },
    addCarousel: function () {
      this.carousels.push({
        url: null,
        target: null,
        editing: true,
        type: 2,
        usageType: usageType,
        sortIndex: 0
      })
    },
    save: function (data) {
      if (data.path) {
        if (data.target) {
          if (data.sortIndex || data.sortIndex === 0) {
            this.$axiosPlus.post('/marketing/banner/', data).then(() => {
            this.$message({
              message: '保存成功',
              type: 'success'
            })
            data.editing = false
          }).catch((error) => {
            this.$message({
              message: error.code + ':' + error.message,
              type: 'error'
            })        
          })
          } else {
            this.$message.error('排序权重不允许为空')
          }
        }
        else {
          this.$message.error('目标指向不允许为空')
        }
      } else {
        this.$message.error('图片不允许为空')
      }
    },
    cancel: function (data) {
      data.editing = false
    },
    reomveImage: function ({row, $index}) {
      if (row.id) {
        this.remove(row.id).then(() => {
          this.images.splice($index, 1)
        })
      } else {
        this.images.splice($index, 1)
      }
    },
    reomveCarousel: function ({row, $index}) {
      if (row.id) {
        this.remove(row.id).then(() => {
          this.carousels.splice($index, 1)
        })
      } else {
        this.carousels.splice($index, 1)
      }
    },
    remove: function (id) {
      return new Promise((resolve) => {
        this.$axiosPlus.delete('/marketing/banner/' + id).then(() => {
          this.$message({
              message: '删除成功',
              type: 'success'
            })  
          resolve()
        }).catch((error) => {
          this.$message({
            message: error.code + ':' + error.message,
            type: 'error'
          })        
        })
      })
    },
    beforeUpload: function (file) {
      this.file = file
    },
    upload: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/marketing/banner/', 30).then((result) => {
          upload.data.path = result.path
          upload.data.url = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
<style scoped>
.image {
  height: 9vh;
  width: 12vh;
}
.full-button {
  width: 100%;
}
</style>