<template>
  <el-form :model="form" label-position="left" label-width="10%" class="loginForm">
    <el-form-item label-width="0">
      <div class="title">
        后台管理系统
      </div>
    </el-form-item>  
    <el-form-item label="用户名">
      <el-input v-model="form.principal" required clearable placeholder="请输入用户名"></el-input>
    </el-form-item>  
    <el-form-item label="密码">
      <el-input v-model="form.credential" required show-password clearable placeholder="请输入密码"></el-input>
    </el-form-item>  
    <el-form-item label-width="0">
      <el-button type="primary" class="button" id="TencentCaptcha" data-appid="2057823109" data-cbfn="callback">登录</el-button>
    </el-form-item>  
  </el-form>
</template>
<script>
export default {
  data: function () {
    return {
      form: {
        principal: '',
        credential: '',
      }
    }
  },
  methods: {
    login: function (res) {
      if(res.ret == 0) {
        this.$axiosPlus.post('/login',{
          principal: this.form.principal,
          credential: this.form.credential,
          ticket: res.ticket,
          randomString: res.randstr
        }).then((result)=>{
          sessionStorage.setItem('token',result.data)
          this.$router.push('/index')
        }).catch((error)=>{
          this.$message({
            message: error.code + ':' + error.message,
            type: 'error'
          })
        })
      }
    }
  },
  mounted: function() {
    if(sessionStorage.getItem('token')){
      this.$router.push('/index')
    }
    window.callback = this.login
  }
}
</script>
<style scoped>
.loginForm {
  position: absolute;
  top: 50vh;
  left: 50vw;
  transform: translate(-50%,-50%);
  width: 30vw;
}

.title {
  font-size: 5vh;
  line-height: 8vh;
}

.button {
  width: 100%;
}
</style>