import axiosPlus from './axiosPlus'

export default {
  upload: function (upload, file, path, duration) {
    return new Promise((resolve, reject) => {
      axiosPlus.get ('/file/uploadUrl', {
        params: {
          contentType: upload.file.type,
          path: path,
          duration: duration
        }
      }).then(res => {
        axiosPlus.originalPut(res.data.presignedUploadUrl,
          file,
        {
          headers: {
            'Content-Type': upload.file.type
          }
        }).then(() => {
          resolve({
            path: res.data.fixedKey,
            name: upload.file.name,
            url: res.data.loadUrl
          })
        }).catch((err) => {
          console.log(err)
          reject(upload.file.name)
        })
      }).catch(error => {
        console.log(error)
        reject(upload.file.name)
      })
    })
  }
}

