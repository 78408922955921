<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-button :loading="creating" @click="create" type="primary">新建品种</el-button>
      </el-row>
      <el-collapse accordion>
        <el-collapse-item v-for="group of list" :key="group.groupName" :title="group.groupName" :name="group.groupName">
          <el-table :data="group.categories" height="40vh">
            <el-table-column prop="name" label="品种名称"></el-table-column>
            <el-table-column prop="description" :show-overflow-tooltip="true" label="描述"></el-table-column>
            <el-table-column prop="showOnIndex" label="首页展示">
              <template #default="list">
                <el-switch disabled v-model="list.row.showOnIndex"></el-switch>
              </template>
            </el-table-column>
            <el-table-column prop="sortIndex" label="排序值"></el-table-column>
            <el-table-column label="操作">
              <template #default="categories">
                <el-button type="primary" size="mini" @click="edit(categories.row.id)">编辑</el-button>
                <el-popconfirm :title="`确定删除${categories.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteCategory(categories.row.id)">
                  <template #reference>
                    <el-button type="danger" size="mini">删除</el-button>
                  </template>
                </el-popconfirm>
              </template>
            </el-table-column>
          </el-table>
        </el-collapse-item>
      </el-collapse>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="品种名称" prop="name">
          <el-input v-model="formData.name" placeholder="品种名称"></el-input>
        </el-form-item>
        <el-form-item label="所属分组" prop="groupId">
          <el-select v-model="formData.groupId" placeholder="选择分组">
            <el-option v-for="option in group.options" :key="option.id" :label="option.name" :value="option.id"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="首选单位" prop="masterUnit">
          <el-select v-model="formData.masterUnit" placeholder="选择首选单位">
            <el-option v-for="option in units" :key="option.value" :label="option.name" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="次要单位" prop="secondUnit">
          <el-select v-model="formData.secondUnit" placeholder="选择次要单位">
            <el-option v-for="option in units" :key="option.value" :label="option.name" :value="option.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="首页展示" prop="showOnIndex">
          <el-switch v-model="formData.showOnIndex"></el-switch>
        </el-form-item>
        <el-form-item label="品种图片" prop="imageUrl">
          <div class="formImage" v-if="formData.imageUrl">
            <el-image :src="formData.imageUrl" fit="contain"></el-image>
          </div>
          <el-upload :http-request="uploadImage" :before-upload="beforeUpload" :show-file-list="false">
            <el-button type="primary">上传</el-button>
          </el-upload>
        </el-form-item>
        <el-form-item label="排序权重" prop="sortIndex">
          <el-input-number v-model="formData.sortIndex" :min="0"></el-input-number>
        </el-form-item>
        <el-form-item label="品种描述" prop="description">
          <el-input v-model="formData.description" type="textarea" :autosize="true" :maxlength="500"></el-input>
        </el-form-item>
        <el-form-item v-if="creating">
          <el-button @click="cancelAdd">取消</el-button>
          <el-button @click="add" type="primary">保存</el-button>
        </el-form-item>
        <el-form-item v-if="editing">
          <el-button @click="cancelEdit">取消</el-button>
          <el-button @click="update" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      units: [
        {
          name: '板',
          value: 2
        },
        {
          name: '棵',
          value: 3
        },
        {
          name: '吨',
          value: 4
        },
        {
          name: '车',
          value: 5
        },
        {
          name: '公斤',
          value: 6
        }
      ],
      file: null,
      list: [],
      creating: false,
      editing: false,
      formData: null,
      group: {
        options: [],
        value: []
      },
      rules: {
        name: {
          required: true,
          message: '品种名称不允许为空',
          trigger: 'blur'
        },
        groupId: {
          required: true,
          message: '所属分组不允许为空',
          trigger: 'blur'
        },
        sortIndex: {
          required: true,
          message: '排序权重不允许为空',
          trigger: 'blur'
        }
      }
    }
  },
  methods: {
    uploadImage: function (upload) {
      return new Promise ((resolve, reject) => {
        this.$cosHelper.upload (upload, this.file, '/quotation/category/', 30).then((result) => {
          this.formData.imageKey = result.path
          this.formData.imageUrl = result.url
          this.$message({
            message: result.name + '上传成功',
            type: 'success'
          })
          resolve()
        }).catch((err) => {
          this.$message.error(err + '上传失败')
          reject()
        })
      })
    },
    beforeUpload: function (file) {
      this.file = file
    },
    loadData: function () {
      this.$axiosPlus.get('/category/list/grouped').then(result => {
        this.list = result.data
      }).catch((err) => {
        console.log(err.response.data.message)
      });
    },
    create: function () {
      this.formData = {
        name: null,
        description: null,
        showOnIndex: false,
        groupId: null,
        imageUrl: null,
        sortIndex: 0,
        masterUnit: null,
        secondUnit: null
      }
      this.creating = true
    },
    cancelAdd: function () {
      this.creating = false
      this.formData = null
    },
    add: function () {
      this.$axiosPlus.post('/category',this.formData).then((result) => {
        this.list = result.data
        this.cancelAdd()
        this.$message({
          message: '保存成功',
          type: 'success'
        })        
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    deleteCategory: function (id) {
      this.$axiosPlus.delete('/category/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (id) {
      this.$axiosPlus.get('/category/' + id).then((result) => {
        this.formData = result.data
        this.editing = true
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    update: function () {
      this.$axiosPlus.put('/category/',this.formData).then((result) => {
        this.list = result.data
        this.cancelEdit()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelEdit: function () {
      this.editing = false
      this.formData = null
    }
  },
  mounted: function () {
    this.loadData()
    this.$axiosPlus.get('/category/group/list').then((result) => {
      this.group.options = result.data
    }).catch((error) => {
      this.$message({
        message: error.code + ':' + error.message,
        type: 'error'
      })        
    })
  }
}
</script>
<style scoped>
.table-container {
  overflow: auto;
}
.space {
  width: 50%;
}
.card {
}
.formImage {
  width: 20vw;
}
</style>