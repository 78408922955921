<template>
  <el-row :gutter="20">
    <el-col :span="12">
      <el-row align="left">
        <el-button :loading="creating" @click="create" :disabled="editing" type="primary">新建分组</el-button>
      </el-row>
      <el-table :data="list" height="80vh">
        <el-table-column type="index" :index="getIndex" label="序号" />
        <el-table-column prop="name" label="名称" />
        <el-table-column prop="code" label="编码">
        </el-table-column>
        <el-table-column label="操作">
          <template #default="group">
            <el-button type="primary" size="mini" @click="edit(group.row)" :disabled="creating">编辑</el-button>
            <el-popconfirm :title="`确定删除${group.row.name}？`" confirm-button-text="删除" cancel-button-text="取消" confirm-button-type="danger" @confirm="deleteTagGroup(group.row.id)">
              <template #reference>
                <el-button type="danger" size="mini">删除</el-button>
              </template>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </el-col>
    <el-col :span="12">
      <el-form v-if="creating || editing" :model="formData" :rules="rules">
        <el-form-item label="分组名称" prop="name">
          <el-input v-model="formData.name" placeholder="分组名称"></el-input>
        </el-form-item>
        <el-form-item label="编码" prop="code">
          <el-input-number :min="0" :max="127" v-model="formData.code"></el-input-number>
        </el-form-item>
        <el-form-item v-if="creating">
          <el-button @click="cancelAdd">取消</el-button>
          <el-button @click="add" type="primary">保存</el-button>
        </el-form-item>
        <el-form-item v-if="editing">
          <el-button @click="cancelEdit">取消</el-button>
          <el-button @click="update" type="primary">保存</el-button>
        </el-form-item>
      </el-form>
    </el-col>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      creating: false,
      editing: false,
      formData: null,
      rules: {
        name: {
          required: true,
          message: '分组名称不允许为空',
          trigger: 'blur'
        },
        code: {
          required: true,
          message: '编码不允许为空',
          trigger: 'blur'
        }
      },
      list: []
    }
  },
  methods: {
    getIndex: function (index) {
      return index === 0 ? 1 : ++index
    },
    loadData: function () {
      this.$axiosPlus.get('/lesson/tagGroup/all').then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    create: function () {
      this.formData = {
        name: null,
        type: 1
      }
      this.creating = true
    },
    add: function () {
      this.$axiosPlus.post('/lesson/tagGroup',this.formData).then((result) => {
        this.list = result.data
        this.cancelAdd()
        this.$message({
          message: '保存成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelAdd: function () {
      this.creating = false
      this.formData = null
    },
    deleteTagGroup: function (id) {
      this.$axiosPlus.delete('/lesson/tagGroup/' + id).then((result) => {
        this.list = result.data
        this.$message({
          message: '删除成功',
          type: 'success'
        })
        this.loadData()}).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    edit: function (data) {
      this.formData = data
      this.editing = true
    },
    update: function () {
      this.$axiosPlus.put('/lesson/tagGroup',this.formData).then((result) => {
        this.list = result.data
        this.cancelEdit()
        this.$message({
          message: '修改成功',
          type: 'success'
        })
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    },
    cancelEdit: function () {
      this.editing = false
      this.formData = null
    }
  },
  mounted: function () {
    this.loadData()
  }
}
</script>
<style scoped>
.transfer {
  text-align: left;
}
</style>