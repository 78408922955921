<template>
  <el-row :gutter="20">
    <el-col :span="5">
      开始日期<el-date-picker v-model="beginTime" type="date" placeholder="选择起始日期" :default-value="now"></el-date-picker>
    </el-col>
    <el-col :span="5">
      结束日期<el-date-picker v-model="endTime" type="date" placeholder="选择结束日期" :default-value="now"></el-date-picker>
    </el-col>
    <el-col :span="5">
      支付状态<el-select v-model="status" placeholder="选择支付状态">
        <el-option :value="-1" label="全部"></el-option>
        <el-option :value="0" label="未支付"></el-option>
        <el-option :value="1" label="已支付"></el-option>
      </el-select>
    </el-col>
    <el-col :span="5">
      业务类型<el-select v-model="type" placeholder="选择业务类型">
        <el-option :value="-1" label="全部"></el-option>
        <el-option :value="0" label="会员购买"></el-option>
        <el-option :value="1" label="品种行情"></el-option>
        <el-option :value="2" label="课程购买"></el-option>
      </el-select>
    </el-col>
    <el-col :span="4">
      <el-button type="primary" @click="loadData">查询</el-button>
    </el-col>
  </el-row>
  <el-row>
    <el-table height="80vh" :data="list" show-summary stripe highlight-current-row>
      <el-table-column prop="time" label="交易时间" width="180"></el-table-column>
      <el-table-column prop="packageName" label="消费服务" width="180"></el-table-column>
      <el-table-column prop="amount" label="金额" width="180"></el-table-column>
      <el-table-column prop="status" label="支付状态" width="180"></el-table-column>
    </el-table>
  </el-row>
</template>
<script>
export default {
  data: function () {
    return {
      beginTime: null,
      endTime: null,
      status: -1,
      type: -1,
      list: []
    }
  },
  methods: {
    loadData: function () {
      let params = {}
      if (this.beginTime) {
        params.beginTime = this.formatBeginTimeString
      }
      if (this.endTime) {
        params.endTime = this.formatEndTimeString
      }
      if (this.status >= 0) {
        params.status = this.status
      }
      if (this.type >= 0) {
        params.type = this.type
      }
      console.log(params)
      this.$axiosPlus.get('/report/payment', {params: params}).then((result) => {
        this.list = result.data
      }).catch((error) => {
        this.$message({
          message: error.code + ':' + error.message,
          type: 'error'
        })        
      })
    }
  },
  mounted: function () {
    let now = new Date()
    this.beginTime = now
    this.endTime = now
    this.loadData()
  },
  computed: {
    formatBeginTimeString: function () {
      return  this.beginTime.getFullYear() + '-' + (this.beginTime.getMonth() + 1) + '-' + (this.beginTime.getDate() + (this.beginTime.getHours() < 16 ? 0 : 1)) + '-00:00:00'
    },
    formatEndTimeString: function () {
      return this.endTime.getFullYear() + '-' + (this.endTime.getMonth() + 1) + '-' + (this.endTime.getDate() + (this.endTime.getHours() < 16 ? 0 : 1)) + '-23:59:59'
    }
  }
}
</script>
<style scoped>

</style>